import React, { useState, useEffect } from 'react'
import * as Production from './styles'

/*
This is the component(s) for the views when person on production team logs in
*/

const _Production = props => {
  return (
    <Production.Layout>
      <h1>hello world!</h1>
    </Production.Layout>
  )
}

export default _Production
