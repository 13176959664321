/*
tasks: [
  {
    title: '5" X 5" X 108" TAN T&G PRIVACY END POST @ 33.23 /ea	',
    currentStation: 0,
    stations: [
      {
        station: 0,
        description: 'Gather the posts',
        quantity: 81,
        comments: [],
        onHold: false,
      },
    ],
    actions: [],
  },
],
*/

export function formatTasks({ column, data }) {
  const startStationIdx = 5
  const endStationIdx = 9
  const titleIdx = 2
  const partNumberIdx = 1
  const quantityIdx = 0

  // Map over the array of row data
  const tasks = data.map(i => {
    const item = i.data

    // Get the stations formatted for the task
    const stations = []
    for (let x = startStationIdx, y = 1; x < endStationIdx + 1; x++, y++) {
      // If the row had the station marked "true", add that station into tasks.station
      if (item[x]) {
        const stationItem = {}
        stationItem.station = y
        stationItem.description = 'Gather sticks'
        stationItem.quantity = 82
        stationItem.onHold = false
        stationItem.onHoldOn = null
        stationItem.comments = []
        stationItem.completedOn = null

        stations.push(stationItem)
      }
    }

    console.log('the stations', stations)

    return {
      partNumber: item[partNumberIdx],
      title: item[titleIdx],
      quantity: parseInt(item[quantityIdx]),
      actions: [],
      stations,
      currentStation: stations[0].station,
    }
  })

  return tasks
}
