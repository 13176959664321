import React, { Component } from 'react'
import { isAfter } from 'date-fns'

import ProjectArchived from './ProjectArchived'
import Loader from '../../sharedComponents/Loader'
import NotFound from '../../routeUtils/notFound'
import EmployeeSurveyReview from './survey/employeeSurveyReview'
import EmployeeAssignEstimator from './estimate/employeeAssignEstimator'
import SurveyContainer from '../../../containers/surveyContainer'
import EstimateContainer from '../../../containers/estimatesContainer'
import EmployeeEstimateReview from './estimate/employeeEstimateReview'
import EmployeePendingApproval from './estimate/EmployeePendingApproval'
import EmployeePendingPayment from './estimate/EmployeePendingPayment'
import EmployeeEstimateCompleted from './estimate/employeeEstimateCompleted'
import EmployeeEstimateExpired from './estimate/EmployeeEstimateExpired'
// import Scheduler from '../../scheduler/Scheduler'
import Scheduler from '../../../containers/schedulerContainer'
import PaperworkContainer from '../../../containers/paperworkContainer'
import InstallationContainer from '../../../containers/installationContainer'
import ProductionContainer from '../../../containers/productionContainer'
import MainContent from '../../sharedComponents/layouts/MainContent'

class ProjectView extends Component {
  componentDidMount() {
    // const project = this.props.items.find(project => {
    //   return project._id === this.props.match.params.id
    // })

    // this.props.setCurrentProject(project)
    const pid = this.props.match.params.id
    this.props.requestProject(pid)
  }

  componentWillUnmount() {
    this.props.resetCurrentProject()
  }
  // componentDidUpdate = prevProps => {
  //   // this is currently used when viewing an archived project
  //   // and hitting unarchive will show the correct view
  //   const prevProject = prevProps.items.find(project => {
  //     return project._id === this.props.match.params.id
  //   })
  //   const currProject = this.props.items.find(project => {
  //     return project._id === this.props.match.params.id
  //   })

  //   if (prevProject.archived !== currProject.archived) {
  //     this.props.setCurrentProject(currProject)
  //   }
  // }

  currentProjectView = project => {
    const estimateExpired = isAfter(
      new Date(),
      project.estimate && project.estimate.expirationDate
        ? project.estimate.expirationDate
        : new Date()
    )

    if (project.archived) {
      return <ProjectArchived project={project} user={this.props.user} {...this.props} />
    }

    switch (project.stage) {
      case 'survey':
        switch (project.survey.status) {
          case 'To Schedule':
            return (
              <Scheduler
                project={project}
                user={this.props.user}
                history={this.props.history}
                isOfsSide={true} // to not show margin for navbar
                isFromQuote={false}
              />
            )
          case 'To Do':
            return <SurveyContainer project={project} {...this.props} />
          case 'In Progress':
            return <SurveyContainer project={project} {...this.props} />
          case 'To Review':
            return <EmployeeSurveyReview project={project} {...this.props} />
          case 'To Revise':
            return <SurveyContainer project={project} {...this.props} />
          // case 'Completed':
          //   return <Loader />
          default:
            return <NotFound />
        }
      case 'estimate':
        if (estimateExpired) {
          return <EmployeeEstimateExpired project={project} {...this.props} />
        }
        switch (project.estimate.status) {
          case 'To Assign':
            return <EmployeeAssignEstimator project={project} {...this.props} />
          case 'To Do':
            return <EstimateContainer project={project} {...this.props} />
          case 'In Progress':
            return <EstimateContainer project={project} {...this.props} />
          case 'To Review':
            return <EmployeeEstimateReview project={project} {...this.props} />
          case 'To Revise':
            return <EstimateContainer project={project} {...this.props} />
          case 'Pending Approval':
            return (
              <EmployeePendingApproval
                project={project}
                estimateId={project.estimate._id}
                {...this.props}
              />
            )
          case 'Pending Payment':
            return <EmployeePendingPayment project={project} {...this.props} />
          case 'Completed':
            return <EmployeeEstimateCompleted project={project} {...this.props} />
          default:
            return <NotFound />
        }

      case 'paperwork':
        return <PaperworkContainer project={project} {...this.props} />

      case 'production':
        return <ProductionContainer project={project} {...this.props} />

      case 'installation':
        return <InstallationContainer project={project} {...this.props} />

      default:
        return ''
    }
  }

  render() {
    const { requestProjectError, requestingProject, project } = this.props

    // Couldnt figure out where the extra margin top was coming from
    const isProductionStage = project && project.stage === 'production'

    if (requestingProject) {
      return <Loader centered={true} />
    }

    if (requestProjectError) return <span>{requestProjectError}</span>

    if (!project) return <NotFound />

    return <MainContent>{this.currentProjectView(project)}</MainContent>
  }
}

export default ProjectView
