import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../helpers/foundation'

import Title from '../../sharedComponents/Title'
import ImageInput from '../../sharedComponents/ImageInput'
import Button from '../../sharedComponents/Button-NEW'

const UploadSiteSurvey = props => {
  function handleUpload(e) {
    e.preventDefault()
    const file = e.target.files[0]
    if (file) {
      props.uploadSiteSurveyAttachment(file)
    }
  }

  return (
    <StyledUploadSiteSurvey>
      <Title text="Upload your site survey." tag="h3" size="24px" />
      <p>To speed things up, upload your site blueprint if you have it.</p>
      <div className="upload">
        <ImageInput
          text={props.siteSurveyAttachment ? 'Change' : 'Upload'}
          isLabel={true}
          variant="nm"
          handleFile={handleUpload}
        />
        <div className="filename">
          {props.siteSurveyAttachment ? props.siteSurveyAttachment.name : ''}
        </div>
      </div>

      <div className="flex split">
        <div
          className="cancel"
          onClick={e => props.submitAndComplete(true, props.user ? false : true)}
        >
          Skip for now
        </div>
        <Button
          variant={`lg ${
            props.siteSurveyAttachment && !props.submitting && !props.submitted ? '' : 'disabled'
          }`}
          text="Complete"
          handleClick={e => {
            if (props.siteSurveyAttachment && !props.submitting && !props.submitted) {
              props.submitAndComplete(false, props.user ? false : true)
            }
          }}
        />
      </div>
    </StyledUploadSiteSurvey>
  )
}

export default UploadSiteSurvey

const StyledUploadSiteSurvey = styled.div`
  .upload {
    margin: 2em 0;
    padding: 1em 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    .filename {
      margin-left: 0.5em;
      font-size: 0.875em;
      color: ${colors.grey};
    }
  }

  .flex {
    display: flex;
    width: 100%;
    &.split {
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  .cancel {
    font-size: 0.875em;
    color: ${colors.grey};
    text-decoration: underline;
    cursor: pointer;
  }
`
