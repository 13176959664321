import React, { Component } from 'react'
import styled from 'styled-components'
import { parseCsv } from './../../utils'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Notice from './../sharedComponents/Notice'
import Icon from './../sharedComponents/Icon'
import Button from '../sharedComponents/Button'
import StyledTable from './../sharedComponents/StyledTable'

const StyledEstimateParts = styled.div`
  .upload-data-container {
    margin-bottom: 2em;
  }

  .edit-pencil {
    text-align: center;
    cursor: pointer;
    padding-right: 5px;
  }

  .totals-container {
    background-color: #f7f8f9;
    padding: 2em 1.5em;
    text-align: right;
  }
`

// 7/8” x 6” x 62” Tan T&G (.050 Wall) Picket Profile @ 6.19 /ea

class EstimateParts extends Component {
  state = {
    columns: [],
    data: [],
    toAdd: [0, 3],
    moneyInputs: [3],
    amountField: 4,
    isEditing: false,
    total: 0,
  }

  componentDidMount() {
    this.setState({
      columns: this.props.estimate.parts.columns,
      data: this.props.estimate.parts.data,
      toAdd: [0, 3],
      moneyInputs: [3],
      amountField: 4,
      isEditing: false,
      total: this.props.estimate.parts.total,
    })
  }

  // upload the csv if, turn the parsed data into the columns & data
  uploadCsv = async e => {
    try {
      const csvArr = await parseCsv(e.target.files[0])
      const columnsArr = csvArr.slice(0, 1)[0] // [[x,x,x]]
      const dataArr = csvArr.slice(1)

      const columns = columnsArr.map((column, idx) => {
        return {
          id: idx,
          text: column,
          editable: [0, 3].includes(idx) ? true : false,
          type: [0, 3].includes(idx) ? 'number' : 'text',
        }
      })

      const data = dataArr.map((item, idx) => {
        return {
          id: idx,
          isEditing: false,
          data: item,
        }
      })

      if (columns.length < 1 || data.length < 1) {
        console.log('something went wrong.')
        return false
      }

      // get totals
      const total = this.getTotals(data)

      this.setState(
        prevState => {
          return {
            total,
            columns,
            data,
          }
        },
        () => {
          // maybe want conditional here to check it the parts are already present
          const { total, columns, data } = this.state
          this.props
            .estimateUpdateServer(this.props.estimate._id, {
              parts: {
                total,
                columns,
                data,
              },
            })
            .then(success => console.log('successfully updated parts'))
        }
      )
    } catch (err) {
      console.log('got err!', err)
      throw err
    }
  }

  // adds amount and blank column(for meta) to columns
  columnsWithMeta = () => {
    if (this.state.columns.length > 0) {
      return [
        ...this.state.columns,
        {
          id: this.state.columns.length + 1,
          text: '',
          editable: false,
          type: 'meta',
        },
      ]
    } else {
      return []
    }
  }

  // puts the data into elems, adds amount by multiplying what needs to be,
  rowsWithMeta = () => {
    if (this.state.columns.length < 1) return []

    return this.state.data.map((row, rowIdx) => {
      // to have the 2 elems that need to be multiplied
      let elemRow = row.data.map((item, itemIdx) => {
        if (this.state.columns[itemIdx].editable) {
          // might want to handle formatting the money here? if you backspace to a single decimal, it will break right now
          item = (
            <input
              className="editable-td"
              type="text"
              value={item}
              disabled={!row.isEditing}
              onChange={e => this.editItem(e, row, itemIdx)}
              // onChange will not give you keycode
              onKeyPress={e => (e.key === 'Enter' ? this.editRow(e, row.id) : null)}
            />
          )
        } else {
          item = <div>{item}</div>
        }
        return item
      })

      return {
        isEditing: row.isEditing,
        data: [
          ...elemRow,
          // show pencil, or editing actions depending on row.isEditing state
          <div>
            {!row.isEditing ? (
              <div className="meta-actions">
                <div className="clickable" onClick={e => this.editRow(e, row.id)}>
                  <Icon icon="pencil" />
                </div>
              </div>
            ) : (
              <div className="meta-actions">
                <div className="clickable confirm" onClick={e => this.editRow(e, row.id)}>
                  <Icon icon="check" color="primary" />
                </div>
              </div>
            )}
          </div>,
        ],
      }
    })
  }

  // returns object containing all of the totals (material, tax, grand)
  getTotals = dataRows => {
    let materialTotal = 0

    // return [ [ qty, price ], [ qty, price ], ... ]
    let totals = dataRows.map(item => {
      let retArr = []
      for (let idx in item.data) {
        if (this.state.toAdd.includes(+idx)) {
          retArr.push(item.data[idx])
        }
      }
      return retArr
    })

    // get the total
    for (let total of totals) {
      materialTotal += Number(total[0]) * Number(total[1])
    }

    return materialTotal.toFixed(2)
  }

  // toggles the edit state of the row
  editRow = (e, rowId) => {
    // get totals
    const total = this.getTotals(this.state.data)
    const uneditedData = this.props.estimate.data

    // TODO: Need to prevent call on every single edit click, compare the two data arrays
    // const dataDiff = difference(this.state.data, uneditedData)
    // console.log(dataDiff)

    this.setState(
      prevState => {
        return {
          total,

          data: prevState.data.map(row => {
            if (row.id === rowId) {
              row.isEditing = !row.isEditing

              // to be returned for the amtField(idx 4 of each row)
              let amtTotal = 0

              row.data = row.data.map((item, itemIdx) => {
                // if the rows column is a row that is needed to calculate the amount
                if (prevState.toAdd.includes(itemIdx)) {
                  // for formatting money (price) & non-money (quantity)
                  if (prevState.moneyInputs.includes(itemIdx)) {
                    item = Number(item).toFixed(2)
                  } else {
                    item = item.split('.')[0]
                  }

                  // if 1st toAdd (quantity) - set it to the state instead of doing 0 * X
                  amtTotal = amtTotal === 0 ? item : amtTotal * item
                }

                // return the new amount instead of returning the current item
                if (itemIdx === prevState.amountField) {
                  item = amtTotal.toFixed(2)
                }
                return item
              })
            }
            return row
          }),
        }
      },
      () => {
        const { columns, data, total } = this.state

        this.props.estimateUpdateServer(this.props.estimate._id, {
          parts: {
            total,
            columns,
            data,
          },
        })
      }
    )
  }

  editItem = (e, row, itemIdx) => {
    const tarVal = e.target.value
    // check to make sure the input is only numeric
    if (isNaN(Number(tarVal))) return false

    this.setState(prevState => {
      return {
        data: prevState.data.map(_row => {
          if (_row.id === row.id) {
            row.data[itemIdx] = tarVal
          }
          return _row
        }),
      }
    })
  }

  render() {
    // console.log('hi state', this.state)
    const { estimate } = this.props
    return (
      <StyledEstimateParts>
        <Header title="Upload and Review the parts list for this project." />

        <Container>
          {estimate.revisionNotes && <Notice text={estimate.revisionNotes} />}
          <div className="upload-data-container">
            <Button variant="lg" isLabel={true} text="Upload Parts CSV">
              <input type="file" accept=".csv" name="partsCsv" onChange={this.uploadCsv} />
            </Button>
          </div>

          <StyledTable>
            <thead>
              <tr>
                {this.columnsWithMeta().map((column, idx) => {
                  return <th key={idx}>{column.text}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {this.rowsWithMeta().map((row, idx) => {
                return (
                  <tr key={idx}>
                    {row.data.map((item, idx) => {
                      return <td key={idx}>{item}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>

          {this.state.data.length > 0 && (
            <div className="totals-container">
              <div className="totals">
                <b>Total</b>
                <div>${this.state.total}</div>
              </div>
            </div>
          )}
        </Container>
      </StyledEstimateParts>
    )
  }
}

export default EstimateParts
