import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { darken, hideVisually } from 'polished'
import { colors } from '../../helpers/foundation'

const Button = ({ isLabel, icon, type, fluid, text, handleClick, children, ...props }) => {
  const variant = props.variant ? props.variant.split(' ') : []

  const theme = {
    size: {
      lg: '1em 3em',
      bordered: '.75em .75em',
      nm: '0.75em 1em',
      sm: '0.5em',
      wide: '0.5em 5em',
    },
    border: {
      bordered: `1px solid ${colors.primary}`,
      borderedGrey: `1px solid ${colors.grey}`,
      borderedDanger: `1px solid ${colors.danger}`,
    },
    backgroundColor: {
      darkerGreen: {
        default: colors.darkerGreen,
        active: darken(0.05, colors.darkerGreen),
      },
      deepGreen: {
        default: colors.deepGreen,
        active: darken(0.05, colors.deepGreen),
      },
      bordered: {
        default: 'transparent',
        active: colors.primary,
      },
      danger: {
        default: colors.danger,
        active: darken(0.05, colors.danger),
      },
      secondary: {
        default: colors.grey3,
        active: darken(0.05, colors.grey3),
      },
      disabled: {
        default: colors.lightGrey,
        active: colors.lightGrey,
      },
      borderedGrey: {
        default: 'transparent',
        active: colors.grey,
      },
      borderedDanger: {
        default: 'transparent',
        active: darken(0.05, colors.danger),
      },
      dark: {
        default: colors.base,
        active: darken(0.05, colors.base),
      },
      warning: {
        default: colors.orange,
        active: darken(0.05, colors.orange),
      },
    },
    color: {
      bordered: {
        default: colors.primary,
        active: '#fff',
      },
      disabled: {
        default: colors.grey2,
      },
      borderedGrey: {
        default: colors.grey,
        active: colors.white,
      },
      borderedDanger: {
        default: colors.danger,
        active: colors.white,
      },
    },
    cursor: {
      disabled: {
        default: 'not-allowed',
      },
    },
    fontSize: {
      lg: '1.25em',
      nm: '0.875em',
      bordered: '.875em',
      sm: '0.6875em',
    },
  }

  const StyledIcon = styled.div`
    display: inline-block;
    vertical-align: baseline;
    margin-right: ${props => {
      if (props.variant.includes('round-sm')) return '0px'
      if (props.variant.includes('round')) return '0px'
      return '10px'
    }};

    svg {
      cursor: pointer;
    }

    svg > path {
      transition: 0.15s ease-in-out;
    }
  `

  const tag = isLabel ? 'label' : 'button'

  const StyledButton = styled(tag)`
    border-radius: ${props => {
      if (variant.includes('round') || variant.includes('round-sm')) {
        return '50%'
      } else {
        return '4px'
      }
    }};
    padding: ${props => {
      if (variant.includes('small-vert-padding')) return '1em'
      if (variant.includes('dynamic')) return '20px 60px'
      if (variant.includes('lg')) return props.theme.size.lg
      if (variant.includes('nm')) return props.theme.size.nm
      if (variant.includes('sm')) return props.theme.size.sm
      if (variant.includes('wide')) return props.theme.size.wide
      if (variant.includes('bordered')) return props.theme.size.bordered
      if (variant.includes('round-sm')) return '0.5em'
      if (variant.includes('round')) return '0.5em'
      return props.theme.size.nm
    }};
    font-weight: 600;
    font-size: ${props => {
      if (variant.includes('dynamic')) return '1rem'
      if (variant.includes('lg')) return props.theme.fontSize.large
      if (variant.includes('sm')) return props.theme.fontSize.sm
      if (variant.includes('nm')) return props.theme.fontSize.nm
      if (variant.includes('bordered')) return props.theme.fontSize.bordered
      return props.theme.fontSize.nm
    }};

    background-color: ${props => {
      if (variant.includes('deepGreen')) return props.theme.backgroundColor.deepGreen.default
      if (variant.includes('darkerGreen')) return props.theme.backgroundColor.darkerGreen.default
      if (variant.includes('bordered')) return props.theme.backgroundColor.bordered.default
      if (variant.includes('danger')) return props.theme.backgroundColor.danger.default
      if (variant.includes('warning')) return props.theme.backgroundColor.warning.default
      if (variant.includes('secondary')) return props.theme.backgroundColor.secondary.default
      if (variant.includes('disabled')) return props.theme.backgroundColor.disabled.default
      if (variant.includes('bordered-grey')) return props.theme.backgroundColor.borderedGrey.default
      if (variant.includes('bordered-danger'))
        return props.theme.backgroundColor.borderedDanger.default
      if (variant.includes('dark')) return props.theme.backgroundColor.dark.default
      return colors.primary
    }};
    border: ${props => {
      if (variant.includes('sameColorBorder')) return props.theme.border.bordered
      if (variant.includes('bordered')) return props.theme.border.bordered
      if (variant.includes('bordered-grey')) return props.theme.border.borderedGrey
      if (variant.includes('bordered-danger')) return props.theme.border.borderedDanger
      return '0'
    }};
    color: ${props => {
      if (variant.includes('bordered')) return props.theme.color.bordered.default
      if (variant.includes('bordered-grey')) return props.theme.color.borderedGrey.default
      if (variant.includes('disabled')) return props.theme.color.disabled.default
      if (variant.includes('bordered-danger')) return props.theme.color.borderedDanger.default
      return '#fff'
    }};
    display: inline-block;
    vertical-align: baseline;
    line-height: 1.15;
    width: ${props => {
      if (fluid) return '100%'
      if (variant.includes('round-sm')) return '34px'
      if (variant.includes('round')) return '48px'
      return 'auto'
    }};
    height: ${props => {
      if (variant.includes('round-sm')) return '34px'
      if (variant.includes('round')) return '48px'
      return 'auto'
    }};
    min-height: ${props => {
      if (variant.includes('nm')) return '32px'
    }};
    min-width: ${props => {
      if (variant.includes('nm')) return '128px'
    }};
    margin-right: ${props => {
      if (variant.includes('round-sm')) return '0px'
      if (variant.includes('round')) return '0px'
      if (variant.includes('nm')) return '0.5em'
      return '0'
    }};
    margin-bottom: ${props => {
      if (variant.includes('round-sm')) return '0px'
      if (variant.includes('round')) return '0px'
      return '0em'
    }};
    cursor: ${props => {
      if (variant.includes('disabled')) return props.theme.cursor.disabled.default
      return 'pointer'
    }};
    text-align: center;
    text-transform: ${props => {
      if (variant.includes('uppercase')) return 'uppercase'
      if (variant.includes('lowercase')) return 'lowercase'
      return 'capitalize'
    }};
    transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      color 0.15s ease-in-out;
    user-select: none;

    &:hover,
    &:focus {
      background-color: ${props => {
        if (variant.includes('deepGreen')) return props.theme.backgroundColor.deepGreen.active
        if (variant.includes('darkerGreen')) return props.theme.backgroundColor.darkerGreen.active
        if (variant.includes('sameColorBorder')) return props.theme.border.bordered.active
        if (variant.includes('bordered')) return props.theme.backgroundColor.bordered.active
        if (variant.includes('bordered-grey'))
          return props.theme.backgroundColor.borderedGrey.active
        if (variant.includes('bordered-danger'))
          return props.theme.backgroundColor.borderedDanger.active
        if (variant.includes('danger')) return props.theme.backgroundColor.danger.active
        if (variant.includes('warning')) return props.theme.backgroundColor.warning.active
        if (variant.includes('secondary')) return props.theme.backgroundColor.secondary.active
        if (variant.includes('disabled')) return props.theme.backgroundColor.disabled.active
        if (variant.includes('dark')) return props.theme.backgroundColor.dark.active
        return darken(0.05, `${colors.primary}`)
      }};
      color: ${props => {
        if (variant.includes('bordered')) return props.theme.color.bordered.active
        if (variant.includes('bordered-grey')) return props.theme.color.borderedGrey.active
        if (variant.includes('bordered-danger')) return props.theme.color.borderedDanger.active
      }};

      svg {
        stroke: #fff;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    > input {
      ${hideVisually()};
    }

    > a {
      color: ${props => {
        if (variant.includes('bordered')) return props.theme.color.bordered.default
        if (variant.includes('bordered-grey')) return props.theme.color.borderedGrey.default
        if (variant.includes('bordered-danger')) return props.theme.color.borderedDanger.default
        if (variant.includes('disabled')) return props.theme.color.disabled.default
        return '#fff'
      }};

      &:hover,
      &:active {
        color: ${props => {
          if (variant.includes('bordered')) return props.theme.color.bordered.default
          if (variant.includes('bordered-grey')) return props.theme.color.borderedGrey.default
          if (variant.includes('bordered-danger')) return props.theme.color.borderedDanger.default
          if (variant.includes('disabled')) return props.theme.color.disabled.default
          return '#fff'
        }};
      }
    }
  `

  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        type={type ? type : ''}
        onClick={handleClick ? () => handleClick() : null}
        onChange={props.handleChange ? e => props.handleChange(e) : null}
      >
        {icon && <StyledIcon variant={variant}>{icon}</StyledIcon>}
        {text}
        {children}
      </StyledButton>
    </ThemeProvider>
  )
}

Button.propTypes = {
  icon: PropTypes.element,
  type: PropTypes.string,
  variant: PropTypes.string,
  fluid: PropTypes.bool,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  isLabel: PropTypes.bool,
}

export default Button
