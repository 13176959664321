import { connect } from 'react-redux'
import Estimate from './../components/estimate/'
import { estimateGet, estimateUpdateServer } from '../redux/modules/estimate/'
import { createProduction } from '../redux/modules/production/operations'
import {
  projectUpdate,
  updateProduction,
  resetStagesFetched,
} from '../redux/modules/projects/index'

const mapStateToProps = state => {
  return {
    loading: state.estimate.loading,
    messages: state.estimate.messages,
    estimate: state.estimate.estimate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    estimateGet: estimateId => dispatch(estimateGet(estimateId)),
    estimateUpdateServer: (estimateId, updates, isCustomer, projectId) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
    createProduction: projectId => dispatch(createProduction(projectId)),
    projectUpdate: (projectId, changes) => dispatch(projectUpdate(projectId, changes)),
    updateProduction: newState => dispatch(updateProduction(newState)),
    resetStagesFetched: () => dispatch(resetStagesFetched()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Estimate)
