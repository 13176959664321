import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import ToDo from './components/ToDo'
import InProgress from './components/InProgress'
import ToReview from './components/ToReview'
import Loader from '../sharedComponents/Loader'
import SidebarLayout from '../sharedComponents/layouts/SidebarLayout'

// View used to show dashboard components within /projects/production

const Production = props => {
  useEffect(() => {
    if (
      !props.project ||
      props.fetchingProduction ||
      props.fetchingProductionError ||
      !isEmpty(props.production)
    ) {
      return
    }
    console.log('running hook!')
    async function fetchProduction() {
      await props.fetchProduction(props.project.production)
    }

    fetchProduction()
  })

  if (props.fetchingProduction) {
    return <Loader centered={true} />
  }

  return (
    <>
      <SidebarLayout
        noActionBar
        statusComponent={
          <>
            {!isEmpty(props.production) ? (
              <>
                {props.production.status === 'To Do' && (
                  <ToDo
                    production={props.production}
                    uploadCsv={props.uploadCsv}
                    csv={props.csv}
                    uploadTasks={props.uploadTasks}
                  />
                )}
                {props.production.status === 'In Progress' && <InProgress />}
                {props.production.status === 'To Review' && <ToReview />}
              </>
            ) : (
              <div />
            )}
          </>
        }
      >
        <div />
      </SidebarLayout>
    </>
  )
}

export default Production
