import { combineReducers } from 'redux'
import types from './types'

const initState = {
  uploadingCsv: false,
  fetchingProduction: false,
  fetchingProductionError: false,
  production: {},
  csv: {},
  uploadingTasks: false,
  uploadingTasksError: null,
}

const teamsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.HANDLE_CSV:
      return {
        ...state,
        csv: action.csv,
      }
    case types.FETCHING_PRODUCTION:
      return {
        ...state,
        fetchingProduction: true,
      }
    case types.FETCHING_PRODUCTION_ERROR:
      return {
        ...state,
        fetchingProduction: false,
        fetchingProductionError: action.error,
      }
    case types.RECIEVED_PRODUCTION:
      return {
        ...state,
        fetchingProduction: false,
        production: action.production,
      }
    case types.UPLOADING_TASKS:
      return {
        ...state,
        uploadingTasks: action.uploadingTasks,
      }
    case types.UPLOADING_TASKS_ERROR:
      return {
        ...state,
        uploadingTasks: false,
        uploadingTasksError: action.error,
      }
    case types.UPDATE_PRODUCTION:
      return {
        ...state,
        production: {
          ...state.production,
          ...action.newProduction,
        },
      }
    default:
      return state
  }
}

export default teamsReducer
