import { addProductionUrl } from '../../../helpers/constants'
import axios from 'axios'
import actions from './actions'
import { updateProduction as projectUpdateProduction } from '../projects/'
import { parseCsv } from '../../../utils'

export const fetchProduction = (productionId = '') => {
  return async dispatch => {
    try {
      dispatch(actions.fetchingProduction())
      const response = await axios.get(`${addProductionUrl()}/api/productions/${productionId}`)
      dispatch(actions.recievedProduction(response.data))
    } catch (err) {
      dispatch(actions.fetchingProductionError())
    }
  }
}

export const createProduction = (projectId = '') => {
  return async dispatch => {
    try {
      const response = await axios.post(`${addProductionUrl()}/api/productions`, {
        projectId,
      })
      return true
    } catch (err) {
      return false
    }
  }
}

export const uploadCsv = csv => {
  return async dispatch => {
    try {
      const csvArr = await parseCsv(csv)
      const columnsArr = csvArr.slice(0, 1)[0] // [[x,x,x]]
      const dataArr = csvArr.slice(1)

      const columns = columnsArr.map((column, idx) => {
        return {
          id: idx,
          text: column,
        }
      })

      const data = dataArr.map((item, idx) => {
        return {
          id: idx,
          data: item,
        }
      })

      console.log('cols', columns)
      console.log('data', data)

      dispatch(actions.handleCsv({ columns, data }))
    } catch (err) {
      console.log('csv upload error')
    }
  }
}

export const uploadTasks = (productionId, formattedTasks) => {
  return async dispatch => {
    try {
      dispatch(actions.uploadingTasks(true))
      const response = await axios.patch(`${addProductionUrl()}/api/productions/${productionId}`, {
        tasks: formattedTasks,
        status: 'In Progress',
      })
      console.log('ok here is resp', response, formattedTasks)
      dispatch(actions.uploadingTasks(false))
      dispatch(actions.updateProduction(response.data))
      return true
    } catch (err) {
      console.log('tasks upload error', err)
      dispatch(actions.uploadingTasksError(err))
    }
  }
}
