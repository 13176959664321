import React from 'react'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import { formatTasks } from '../utils'
import { colors } from '../../../helpers/foundation'

import ImageInput from '../../sharedComponents/ImageInput'
import Button from '../../sharedComponents/Button-NEW'
import Header from '../../sharedComponents/Header'
import Container from '../../sharedComponents/Container'
import Table from '../../sharedComponents/Table-NEW'

const ToDo = props => {
  async function handleUpload(e) {
    await props.uploadCsv(e.target.files[0])
  }

  async function goNext() {
    const formattedTasks = formatTasks(props.csv)
    const updatedProduction = await props.uploadTasks(props.production._id, formattedTasks)
    console.log('updated production', updatedProduction)
  }

  return (
    <StyledTodo>
      <Header title="Start the production of the project" noBorderBottom />

      <Container>
        {!isEmpty(props.csv) && (
          <Table
            header="CSV File name"
            tableHeads={props.csv.columns.map(col => col.text)}
            tableRows={props.csv.data.map(data => data.data)}
            widths={[0.5, 1, 5.5, 0.5, 1, 0.5, 0.5, 0.5, 0.5, 0.5]}
            marginBottom="28px"
          />
        )}
        <p className="body-text">
          Upload your .CSV file to start the production of the project. Contrary to popular belief,
          Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
          literature from 45 BC, making it over 2000 years old.
        </p>
        <div className="split">
          <Button
            text="Continue"
            variant={`lg ${!isEmpty(props.csv) ? '' : 'disabled'}`}
            handleClick={e => (!isEmpty(props.csv) ? goNext() : null)}
          />
          <ImageInput
            text={isEmpty(props.csv) ? 'Upload CSV' : 'Change CSV'}
            isLabel={true}
            variant="nmlg"
            color="deepGreen"
            handleFile={handleUpload}
            accept={['.csv']}
          />
        </div>
      </Container>
    </StyledTodo>
  )
}

{
  /* <StyledTodo>
  <ImageInput
    text="Upload CSV"
    isLabel={true}
    variant="nm"
    handleFile={handleUpload}
    accept={['.csv']}
  />

  {!isEmpty(props.csv) && (
    <table>
      <thead>
        <tr>
          {props.csv.columns.map(col => {
            return <th key={col.id}>{col.text}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {props.csv.data.map(row => {
          return (
            <tr key={row.id}>
              {row.data.map((item, idx) => {
                return <td key={idx}>{item}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )}

  {!isEmpty(props.csv) && <Button text="Start production" handleClick={goNext} />}
</StyledTodo> */
}

export default ToDo

const StyledTodo = styled.div`
  p.body-text {
    max-width: 40em;
    font-size: 0.875em;
    color: ${colors.grey2};
    margin-bottom: 28px;
  }

  .split {
    display: flex;
    /* justify-content: space-between; */
    button:first-child {
      margin-right: 1.5em;
    }
  }
`
