import React from 'react'
import styled from 'styled-components'
import { colors } from '../../helpers/foundation'

const Table = props => {
  console.log('wtf?', props)
  return (
    <StyledTable {...props}>
      <div className="table-title">{props.header}</div>
      <div className="table-heads">
        {props.tableHeads.map((head, idx) => {
          return (
            <div key={idx} className={`table-head width-${props.widths[idx] * 10}`}>
              {head}
            </div>
          )
        })}
      </div>
      <div className="table-rows">
        {props.tableRows.map((row, idx) => {
          return (
            <div key={idx} className="table-row">
              {row.map((rowData, idx) => {
                return (
                  <div key={idx} className={`table-data width-${props.widths[idx] * 10}`}>
                    {rowData}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </StyledTable>
  )
}

export default Table

const StyledTable = styled.div`
  width: 100%;
  border: 1px solid ${colors.grey6};
  border-radius: 4px;
  margin-bottom: ${props => props.marginBottom && props.marginBottom};

  .table-title {
    background-color: ${colors.grey5};
    padding: 20px 16px;
    font-size: 1.25em;
    font-weight: 600;
    border-bottom: 1px solid ${colors.grey6};
  }

  .table-row,
  .table-heads {
    padding: 12px 16px;
  }

  .table-head {
    font-size: 0.875em;
    font-weight: 600;
    padding-left: 0.5em;
  }

  .table-row {
    &:nth-child(even) {
    }
    &:nth-child(odd) {
      background-color: ${colors.grey5};
    }
  }

  .table-data {
    font-size: 0.875em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0.5em;
  }

  .table-heads,
  .table-row {
    display: flex;
    .table-head,
    .table-data {
      &.width-5 {
        width: 5%;
      }
      &.width-10 {
        width: 10%;
      }
      &.width-15 {
        width: 15%;
      }
      &.width-20 {
        width: 20%;
      }
      &.width-25 {
        width: 25%;
      }
      &.width-30 {
        width: 30%;
      }
      &.width-35 {
        width: 35%;
      }
      &.width-40 {
        width: 40%;
      }
      &.width-45 {
        width: 45%;
      }
      &.width-50 {
        width: 50%;
      }
      &.width-55 {
        width: 55%;
      }
      &.width-60 {
        width: 60%;
      }
      &.width-65 {
        width: 65%;
      }
      &.width-70 {
        width: 70%;
      }
      &.width-75 {
        width: 75%;
      }
      &.width-80 {
        width: 80%;
      }
      &.width-85 {
        width: 85%;
      }
      &.width-90 {
        width: 90%;
      }
      &.width-95 {
        width: 95%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
`
