import { connect } from 'react-redux'
import Production from '../components/production/index'
import { fetchProduction, uploadCsv, uploadTasks } from '../redux/modules/production/operations'
import { estimateUpdateServer } from '../redux/modules/estimate/index'

const mapStateToProps = state => {
  return {
    uploadingCsv: state.production.uploadingCsv,
    fetchingProduction: state.production.fetchingProduction,
    fetchingProductionError: state.production.fetchingProductionError,
    production: state.production.production,
    csv: state.production.csv,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProduction: productionId => dispatch(fetchProduction(productionId)),
    uploadCsv: csv => dispatch(uploadCsv(csv)),
    estimateUpdateServer: (estimateId, updates) =>
      dispatch(estimateUpdateServer(estimateId, updates)),
    uploadTasks: (productionId, formattedTasks) =>
      dispatch(uploadTasks(productionId, formattedTasks)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Production)
