import React, { Component, Fragment, PureComponent } from 'react'
import { Switch, Route } from 'react-router-dom'
import store from 'store'
import { isEqual, isEmpty } from 'lodash'
import { isWithinRange, subDays } from 'date-fns'
import isRole from './../../../helpers/roles'
import {
  SurveyIcon,
  EstimateIcon,
  PaperworkIcon,
  InstallationIcon,
  MailboxIcon,
} from './../../sharedComponents/StyledIcons'

import ProjectsDashboard from './ProjectsDashboard'
import AdminDashboard from './../../../containers/globalSettingsContainer'
import MainContent from './../../sharedComponents/layouts/MainContent'
import Controls from './Controls'
import Search from './../../sharedComponents/Search'
import NotFound from './../../routeUtils/notFound'
import NotPermitted from './../../routeUtils/NotPermitted'
import Icon from './../../sharedComponents/Icon'
import Filters from './Filters'
import Tabs from './../../sharedComponents/Tabs'
// import ProjectView from './ProjectView'
// import Customer from './../../customer/'

class Projects extends Component {
  _isMounted = false

  state = {
    filteredProjects: [],
    filteredQuotes: [],
    quotesFetched: false,
    // pass into ProjectList for quotes, so quotes dont render before other ProjectCards
    projectsFetched: false,

    // filter state
    filtersOpen: false,
    compactView: false,
    archiveHidden: false,
    showCreatedBy: 'any',
    dateRange: 'any',
    rememberSettings: true,
  }

  componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      this.populateStateFromLS()

      // load the global settings if they havent already been loaded
      if (!this.props.globalSettingsLoaded) {
        // console.log('loading the global settings in Projects/index')
        this.props.fetchGlobalSettings()
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    // unset props.stagesFetched, props.items, props.quotes
    this.props.resetStagesFetched()
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.items, this.props.items) ||
      !isEqual(prevProps.quotes, this.props.quotes)
    ) {
      this.setState({
        filteredProjects: this.props.items,
        filteredQuotes: this.props.quotes || [],
        projectsFetched: true,
      })
    }
  }

  populateStateFromLS = () => {
    const filters = store.get('projectFilters')
    if (this._isMounted) {
      const filtersForState = {}

      // dont try to populate from LS if filters havent been set
      if (!filters || isEmpty(filters)) {
        console.log('no filters?')
        return false
      }

      if (filters.compactView) filtersForState['compactView'] = filters.compactView
      if (filters.archiveHidden) filtersForState['archiveHidden'] = filters.archiveHidden
      if (filters.showCreatedBy !== 'any') filtersForState['showCreatedBy'] = filters.showCreatedBy
      if (filters.dateRange !== 'any') filtersForState['dateRange'] = filters.dateRange

      this.setState(prevState => ({
        ...filtersForState,
        rememberSettings: true,
      }))
    }
  }

  activeSearch = (projects, quotes) => {
    console.log('am i searching?')
    this.setState({
      filteredProjects: projects,
      // see if quotes are being used, if so, do the filter for the quotes
      ...(Array.isArray(quotes) && { filteredQuotes: quotes }),
    })
  }

  filterProjectsByStage = stage => {
    return this.state.filteredProjects.filter(project => {
      return project.stage === stage
    })
  }

  toggleFiltersOpen = () => {
    // no need to store in LS
    this.setState(prevState => ({ filtersOpen: !prevState.filtersOpen }))
  }

  saveFilterSettings = ({
    compactView = false,
    archiveHidden = false,
    showCreatedBy = 'any',
    dateRange = 'any',
    rememberSettings = false,
  }) => {
    this.setState(
      prevState => ({
        filtersOpen: false,
        compactView,
        archiveHidden,
        showCreatedBy,
        dateRange,
        rememberSettings,
      }),
      () => {
        store.remove('projectFilters')
        // set options in local storage
        if (rememberSettings) {
          const filtersForState = {}
          if (compactView) filtersForState['compactView'] = compactView
          if (archiveHidden) filtersForState['archiveHidden'] = archiveHidden
          if (showCreatedBy !== 'any') filtersForState['showCreatedBy'] = showCreatedBy
          if (dateRange !== 'any') filtersForState['dateRange'] = dateRange
          store.set('projectFilters', filtersForState)
        }
      }
    )
  }

  clearFilterSettings = () => {
    this.setState(
      prevState => ({
        compactView: false,
        archiveHidden: false,
        showCreatedBy: 'any',
        dateRange: 'any',
        rememberSettings: true,
      }),
      () => {
        store.remove('projectFilters')
      }
    )
  }

  // return array of objects applying the filters
  // call like: var items = getItemsBasedOnFilters()(stage)
  getItemsBasedOnFilters = () => {
    return stage => {
      const { filteredProjects, archiveHidden, showCreatedBy, dateRange } = this.state
      // filter by stage if it was passed in, otherwise use all items
      let items = stage ? this.filterProjectsByStage(stage) : this.props.items
      if (archiveHidden) {
        items = items.filter(project => {
          if (!project.archived) {
            return true
          } else {
            return false
          }
        })
      }
      // filter showCreatedBy
      if (showCreatedBy && showCreatedBy !== 'any') {
        items = items.filter(project => {
          const scheduledByCustomer = project.scheduledBy && project.scheduledBy === 'Customer'
          if (showCreatedBy === 'customer') {
            return scheduledByCustomer
          } else if (showCreatedBy === 'employee') {
            return !scheduledByCustomer
          } else {
            return true
          }
        })
      }
      // filter by date range
      if (dateRange && dateRange !== 'any') {
        items = items.filter(project => {
          return isWithinRange(project.createdAt, subDays(new Date(), dateRange), new Date())
        })
      }

      return items
    }
  }

  render() {
    const { items, user, isLoading, hasErrored, quotes } = this.props
    const {
      filteredProjects,
      filtersOpen,
      compactView,
      archiveHidden,
      showCreatedBy,
      dateRange,
      rememberSettings,
    } = this.state

    if (hasErrored) {
      return <p>Sorry something wen't wrong, we couldn't load your projects at this time.</p>
    }

    // get tabs based on the users role
    const tabs = []

    const surveyTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).survey ||
      isRole(this.props.user).customerService
        ? true
        : false
    const estimateTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false
    const paperworkTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false
    const productionTabVisible = true
    const installationTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false

    if (surveyTabVisible) {
      tabs.push({
        name: 'Survey',
        color: 'blue',
        link: '/projects/surveys',
        icon: <SurveyIcon />,
      })
    }
    if (estimateTabVisible) {
      tabs.push({
        name: 'Estimate',
        color: 'purple',
        link: '/projects/estimates',
        icon: <EstimateIcon />,
      })
    }
    if (paperworkTabVisible) {
      tabs.push({
        name: 'Paperwork',
        color: 'orange',
        link: '/projects/paperwork',
        icon: <PaperworkIcon />,
      })
    }
    if (productionTabVisible) {
      tabs.push({
        name: 'Production',
        color: 'primary',
        link: '/projects/production',
        icon: <MailboxIcon />,
      })
    }
    if (installationTabVisible) {
      tabs.push({
        name: 'Install',
        color: 'pink',
        link: '/projects/installation',
        icon: <InstallationIcon />,
      })
    }

    // get the items based on the filters
    const itemsAfterFilters = this.getItemsBasedOnFilters()

    return (
      <MainContent>
        <Route
          path="/projects"
          render={props => (
            <Fragment>
              <Controls>
                {/* this will be shown when screen is below certain width, and one in Projects/index.js will be used at large screen */}
                <div className="tabs-responsive-container">
                  <div className="inner">
                    <Tabs type="link" tabs={tabs} />
                  </div>
                </div>
                <div className="actions">
                  <div className="buttons" />
                  <div className="search-bar">
                    <Search
                      handleChange={this.activeSearch}
                      items={this.props.items}
                      quotes={quotes}
                      properties={['customer.displayName', 'additionalClientInfo.address']}
                    />
                  </div>
                  <div className="filters-container">
                    <div className="filters-icon" onClick={this.toggleFiltersOpen}>
                      <Icon icon="filter" />
                    </div>
                    {filtersOpen && (
                      <Filters
                        _compactView={compactView}
                        _archiveHidden={archiveHidden}
                        _dateRange={dateRange}
                        _showCreatedBy={showCreatedBy}
                        _rememberSettings={rememberSettings}
                        clearFilterSettings={this.clearFilterSettings}
                        saveFilterSettings={this.saveFilterSettings}
                      />
                    )}
                  </div>
                </div>
              </Controls>
            </Fragment>
          )}
        />

        <Switch>
          <Route
            exact={true}
            path="/"
            render={props => (
              <AdminDashboard
                projects={items}
                fetchGlobalSettings={this.props.fetchGlobalSettings}
                globalSettings={this.props.globalSettings}
                settingsLoaded={this.props.settingsLoaded}
                user={this.props.user}
                {...props}
              />
            )}
          />

          <Route
            exact={true}
            path="/projects"
            key="projects"
            render={props => (
              <ProjectsDashboard stage="" projects={filteredProjects} user={user} {...props} />
            )}
          />

          <Route
            exact={true}
            path="/projects/surveys"
            key="surveys"
            render={props => {
              if (isRole(user).admin || isRole(user).survey || isRole(user).customerService) {
                return (
                  <ProjectsDashboard
                    stage="survey"
                    globalSettings={this.props.globalSettings}
                    projects={itemsAfterFilters('survey')}
                    fetchData={this.props.fetchData}
                    stagesFetched={this.props.stagesFetched}
                    resetStagesFetched={this.props.resetStagesFetched}
                    isLoading={this.props.isLoading}
                    user={user}
                    compact={compactView}
                    fetchQuotes={async () => {
                      // prevent endless request/render
                      this.setState({ quotesFetched: true })
                      this.props.fetchQuotes()
                    }}
                    // quotes={this.props.quotes}
                    quotes={this.state.filteredQuotes}
                    quotesFetched={this.state.quotesFetched}
                    projectsFetched={this.state.projectsFetched}
                    deleteProject={this.props.deleteProject}
                    deleteQuote={this.props.deleteQuote}
                    {...props}
                  />
                )
              } else {
                return <NotPermitted {...props} />
              }
            }}
          />

          <Route
            exact={true}
            path="/projects/estimates"
            key="estimates"
            render={props => {
              if (isRole(user).admin || isRole(user).estimate || isRole(user).customerService) {
                return (
                  <ProjectsDashboard
                    stage="estimate"
                    globalSettings={this.props.globalSettings}
                    projects={itemsAfterFilters('estimate')}
                    fetchData={this.props.fetchData}
                    stagesFetched={this.props.stagesFetched}
                    resetStagesFetched={this.props.resetStagesFetched}
                    isLoading={this.props.isLoading}
                    user={user}
                    compact={compactView}
                    deleteProject={this.props.deleteProject}
                    {...props}
                  />
                )
              } else {
                return <NotPermitted {...props} />
              }
            }}
          />

          {/* TODO: add the security here */}
          <Route
            exact={true}
            path="/projects/paperwork"
            key="paperwork"
            render={props => (
              <ProjectsDashboard
                stage="paperwork"
                globalSettings={this.props.globalSettings}
                projects={itemsAfterFilters('paperwork')}
                fetchData={this.props.fetchData}
                stagesFetched={this.props.stagesFetched}
                resetStagesFetched={this.props.resetStagesFetched}
                isLoading={this.props.isLoading}
                user={user}
                compact={compactView}
                deleteProject={this.props.deleteProject}
                {...props}
              />
            )}
          />

          <Route
            exact={true}
            path="/projects/production"
            key="production"
            render={props => (
              <ProjectsDashboard
                stage="production"
                globalSettings={this.props.globalSettings}
                projects={itemsAfterFilters('production')}
                fetchData={this.props.fetchData}
                stagesFetched={this.props.stagesFetched}
                resetStagesFetched={this.props.resetStagesFetched}
                isLoading={this.props.isLoading}
                user={user}
                compact={compactView}
                deleteProject={this.props.deleteProject}
                {...props}
              />
            )}
          />

          {/* TODO: add the security here */}
          <Route
            exact={true}
            path="/projects/installation"
            key="installation"
            render={props => (
              <ProjectsDashboard
                stage="installation"
                globalSettings={this.props.globalSettings}
                projects={itemsAfterFilters('installation')}
                fetchData={this.props.fetchData}
                stagesFetched={this.props.stagesFetched}
                resetStagesFetched={this.props.resetStagesFetched}
                isLoading={this.props.isLoading}
                user={user}
                compact={compactView}
                deleteProject={this.props.deleteProject}
                {...props}
              />
            )}
          />

          <Route component={NotFound} />
        </Switch>
      </MainContent>
    )
  }
}

export default Projects
