import { em } from 'polished'

export const colors = {
  danger: '#FF495C',
  success: '#94d82d',
  primary: '#94d82d',
  darkerGreen: '#74B816',
  deepGreen: '#00964E',
  orange: '#FF922B',
  blue: '#4DABF7',
  purple: '#7048E8',
  pink: '#E64980',
  base: '#343A40',
  white: '#ffffff',
  lightGrey: '#E9ECEF',
  grey: '#ADB5BD',
  grey2: '#868E96',
  grey3: '#495057',
  grey4: '#343A40',
  grey5: '#f7f8f9',
  grey6: '#CED4DA',
  grey7: '#F1F3F5',
}

// should we have fonts in here

export const blueprintColors = {
  danger: '#FF495C',
  grey: '#ADB5BD',
  green: '#94D82D',
  blue: '#4DABF7',
  orange: '#FF922B',
  pink: '#E64980',
  purple: '#7048E8',
}

export const breakpoints = {
  projectTabsMax: '1480',
  large: '1024',
  medium: '790',
  aboveTablet: '820',
  small: '480',
}

export const boxShadows = {
  shadow1: '0px 4px 8px #000',
}

export const breakpoint = size => {
  return `@media only screen and (min-width: ${em(`${breakpoints[size]}px`)})`
}

export const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

export const viewportHeight = Math.max(
  document.documentElement.clientHeight,
  window.innerHeight || 0
)

export const navSize = {
  large: '106px',
  small: '89px',
}

export const actionBarSize = {
  small: '60px',
}

export const mobileActionSize = '50px'

export const controlsSize = {
  small: '90px',
  large: '48px',
}

export const typography = {
  1: em('32px'),
  2: em('24px'),
  3: em('16px'),
}

export const zIndexes = {
  base: 3,
  tabs: 4,
  panel: 5,
  panelOverlay: 5,
  panelWithOverlay: 5,
  nav: 6,
  actionBar: 6,
  modalOverlay: 7,
  // modalInner: 8,
  thumbnailDot: 9,
  notificationsComponent: 8,
  sidebar: 10,
  revealMenu: 10,
  modalOuter: 11,
  modalInner: 12,
  viewer: 13,
  alert: 14,
}

export const regexes = {
  email: /[\w-]+@([\w-]+\.)+[\w-]+/,
}

// Typography
// Acumin Pro
// 400
// 600
// 700
