import React, { Component } from 'react'
import styled from 'styled-components'

import Panel from './../../sharedComponents/Panel'
import Alert from './../../sharedComponents/Alert'
import ActionBar from './../ActionBar'
import Overlay from './../Overlay'
import {
  colors,
  breakpoints,
  viewportWidth,
  // navSize,
  actionBarSize,
  mobileActionSize,
} from './../../../helpers/foundation'

const StyledContainer = styled.div`
  transition: margin 0.2s ease-out, width 0.2s ease-out;
  padding-bottom: calc(${actionBarSize.small} + ${mobileActionSize});

  @media screen and (min-width: ${breakpoints.medium}px) {
    width: ${props => (props.steps && props.stepsOpen ? 'calc(100% - 240px)' : '100%')};
    margin-left: ${props =>
      props.historyOpen ? '700px' : props.steps && props.stepsOpen ? '240px' : '0'};
    padding-bottom: ${actionBarSize.small};
  }
`

class SidebarLayout extends Component {
  _isMounted = false

  state = {
    stepsOpen: false,
    historyOpen: false,
    commentsOpen: false,
    faqsOpen: false,
    overlayActive: false,
  }

  componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      if (viewportWidth >= breakpoints.medium && this.props.children) {
        this.setState({
          stepsOpen: true,
        })
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  toggleSteps = () => {
    this.setState({
      stepsOpen: !this.state.stepsOpen,
    })
  }

  toggleHistory = () => {
    this.setState({
      historyOpen: !this.state.historyOpen,
    })
  }

  toggleFaqs = () => {
    this.setState({
      faqsOpen: !this.state.faqsOpen,
      overlayActive: !this.state.overlayActive,
    })
  }

  resetPanels = () => {
    this.setState({
      commentsOpen: false,
      faqsOpen: false,
      overlayActive: false,
    })
  }

  render() {
    const { statusComponent, children, faqs, history, error, handleError } = this.props
    const { stepsOpen, historyOpen, faqsOpen, overlayActive } = this.state

    // console.log('err?', error)

    // for some reason, error is being passed down after wiring up offline for the survey

    return (
      <div>
        <Overlay active={overlayActive} handleClick={this.resetPanels} />

        {/* {error && (
          <Alert status="danger" dismiss={() => handleError(null)} timer={5}>
            {error}
          </Alert>
        )} */}

        {children && (
          <Panel
            open={stepsOpen}
            size="240"
            color={colors.white}
            attached={true}
            position="left"
            noBorder={this.props.noBorder ? true : false}
            customPadding={this.props.customPadding ? this.props.customPadding : null}
          >
            {children}
          </Panel>
        )}

        {faqs && (
          <Panel
            open={faqsOpen}
            size="550"
            color={colors.grey7}
            attached={false}
            padded={true}
            position="right"
          >
            <h3>FAQ</h3>
            {faqs}
          </Panel>
        )}

        {history && historyOpen && (
          <Panel
            open={this.state.historyOpen}
            size="700"
            color={colors.grey7}
            attached={true}
            position="left"
          >
            {history}
          </Panel>
        )}

        <StyledContainer steps={children} stepsOpen={stepsOpen} historyOpen={historyOpen}>
          {statusComponent}
          {!this.props.noActionBar && (
            <ActionBar
              stepsOpen={stepsOpen}
              faqsOpen={faqsOpen}
              historyOpen={historyOpen}
              faqs={faqs}
              history={history}
              steps={children}
              toggleSteps={this.toggleSteps}
              toggleHistory={this.toggleHistory}
              toggleFaqs={this.toggleFaqs}
            />
          )}
        </StyledContainer>
      </div>
    )
  }
}

export default SidebarLayout
