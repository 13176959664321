const CREATE_PRODUCTION = 'app/production/CREATE_PRODUCTION'
const FETCHING_PRODUCTION = 'app/production/FETCHING_PRODUCTION'
const FETCHING_PRODUCTION_ERROR = 'app/production/FETCHING_PRODUCTION_ERROR'
const RECIEVED_PRODUCTION = 'app/production/RECIEVED_PRODUCTION'
const HANDLE_CSV = 'app/production/HANDLE_CSV'
const UPLOADING_TASKS = 'app/production/UPLOADING_TASKS'
const UPLOADING_TASKS_ERROR = 'app/production/UPLOADING_TASKS_ERROR'
const UPDATE_PRODUCTION = 'app/production/UPDATE_PRODUCTION'

export default {
  CREATE_PRODUCTION,
  FETCHING_PRODUCTION,
  FETCHING_PRODUCTION_ERROR,
  RECIEVED_PRODUCTION,
  HANDLE_CSV,
  UPLOADING_TASKS,
  UPLOADING_TASKS_ERROR,
  UPDATE_PRODUCTION,
}
