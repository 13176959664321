import types from './types'

const handleCsv = (csvObj = {}) => ({
  type: types.HANDLE_CSV,
  csv: csvObj,
})

const fetchingProduction = () => ({
  type: types.FETCHING_PRODUCTION,
})

const fetchingProductionError = error => ({
  type: types.FETCHING_PRODUCTION_ERROR,
  error,
})

const recievedProduction = production => ({
  type: types.RECIEVED_PRODUCTION,
  production,
})

const uploadingTasks = bool => ({
  type: types.UPLOADING_TASKS,
  uploadingTasks: bool,
})

const uploadingTasksError = error => ({
  type: types.UPLOADING_TASKS_ERROR,
  error,
})

const updateProduction = (newProduction = {}) => ({
  type: types.UPDATE_PRODUCTION,
  newProduction,
})

export default {
  handleCsv,
  fetchingProduction,
  fetchingProductionError,
  recievedProduction,
  uploadingTasks,
  uploadingTasksError,
  updateProduction,
}
