import React, { Component, PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

const ViewerContext = React.createContext()

class ViewerProvider extends PureComponent {
  state = {
    currentImage: null,
  }

  componentDidMount = () => {}

  setImage = imageUrl => {
    this.setState(prevState => ({ currentImage: imageUrl }))
  }

  unsetImage = () => {
    this.setState(prevState => ({ currentImage: null }))
  }

  render() {
    return (
      <ViewerContext.Provider
        value={{
          ...this.state,
          setImage: this.setImage,
          unsetImage: this.unsetImage,
        }}
      >
        {this.props.children}
      </ViewerContext.Provider>
    )
  }
}

export const ViewerConsumer = ViewerContext.Consumer

export const withViewerContext = Component => {
  return props => {
    return (
      <ViewerContext.Consumer>
        {context => <Component {...props} context={context} />}
      </ViewerContext.Consumer>
    )
  }
}

export default withRouter(ViewerProvider)
