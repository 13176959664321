import React, { Component } from 'react'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Button from './../sharedComponents/Button'

class EstimateStart extends Component {
  skipEstimateToProduction = async () => {
    try {
      // Update project to be production stage
      await this.props.projectUpdate(this.props.project._id, { stage: 'production' })
      // Create the item for production if it does not already exist
      await this.props.createProduction(this.props.project._id)

      this.props.resetStagesFetched()
      setTimeout(() => {
        this.props.history.push('/projects/production')
      }, 500)
    } catch (err) {
      console.log('There was an error', err)
    }
  }

  render() {
    console.log('the props!', this.props)
    return (
      <div>
        <Header title="Start the estimation." />
        <Container>
          <Button variant="lg" text="Start Estimation" handleClick={this.props.startEstimation} />
          <Button
            variant="lg danger"
            text="Skip to Production"
            handleClick={this.skipEstimateToProduction}
          />
        </Container>
      </div>
    )
  }
}

export default EstimateStart
